import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import SwiperCore, { Lazy, Swiper as SwiperJs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImageInterface } from "../../types/SanityTypes";
import SlideV4 from "../SlideV4";
import "swiper/css";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { ArrowRight } from "../../images/icons/arrow-right";
import { ArrowLeft } from "../../images/icons/arrow-left";
import { event12 } from "../../analytics/event12";
import { event66 } from "../../analytics/event66";
import Accessibility from "../Accessibility";

interface ProductV4Interface {
  _id: string;
  _rawImages: ImageInterface[];
  title: string;
  url: string;
  smartProductId: string;
  paSmartProductId: string;
  paUPC: string;
  subBrand: {
    name: string;
  };
  name: string;
  slug: {
    current: string;
  };
}

export interface SliderBlockV4Interface {
  data: ProductV4Interface[];
  textColor?: string;
  type: string;
  variation?: string;
  _rawImage?: ImageInterface;
  url?: string;
  title?: string;
}

SwiperCore.use([Lazy]);

const SliderBlockV4: FunctionComponent<SliderBlockV4Interface> = ({ data, type }) => {
  const { sanityAccessibility, sanitySiteSettings } = useContext(LocalizedContext);
  const { sanityProductLandingPage, sanitySearch } = useContext(LocalizedContext);
  const { previous, next } = sanityAccessibility || {};
  const [width, setWidth] = useState(992);
  const elementNum = data.length;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [swiper, setSwiper] = useState(new SwiperJs(""));

  const swiperNext = () => {
    if (swiper) {
      swiper.slideNext();
      event66(next ? next : "slider next");
    }
  };

  const swiperPrev = () => {
    if (swiper) {
      swiper.slidePrev();
      event66(previous ? previous : "slider previous");
    }
  };

  const setBreakPoints = {
    375: {
      initialSlide: 0,
      spaceBetween: 8,
      slidesPerView: 1.5,
      scrollbar: {
        draggable: true
      }
    },
    576: {
      initialSlide: 0,
      spaceBetween: 8,
      slidesPerView: 2,
      scrollbar: {
        draggable: true
      }
    },
    768: {
      initialSlide: 0,
      spaceBetween: 16,
      slidesPerView: 3,
      scrollbar: {
        draggable: true
      }
    },
    1200: {
      initialSlide: 0,
      spaceBetween: 16,
      slidesPerView: 4,
      scrollbar: {
        draggable: true
      }
    }
  };

  const renderSlides = () => {
    return (data as unknown as ProductV4Interface[]).map((item: ProductV4Interface, index: number) => {
      const renderImage =
        item._rawImages === null ? (sanitySearch?._rawPlaceholder as ImageInterface) : item._rawImages[0];

      const onClick = () =>
        event12(
          item._id,
          item.name ? item.name : "",
          item.subBrand ? item.subBrand.name : "",
          sanitySiteSettings.brandName,
          index,
          "Product Slider"
        );

      return (
        <SwiperSlide className="swiper-slider-v4" key={`${item.name}-${index}`}>
          {!item ? null : (
            <SlideV4
              key={`${item.name}-${index}`}
              link={`${sanityProductLandingPage?.slug.current}/${item.slug?.current}`}
              image={renderImage}
              heading={item.name ? item.name : ""}
              id={item._id}
              upc={item.smartProductId || item.paSmartProductId || item.paUPC}
              onClick={onClick}
              subBrand={item.subBrand ? item.subBrand.name : ""}
            />
          )}
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="slider-block-v4">
      <Swiper
        key={type}
        speed={700}
        lazy={true}
        preloadImages={true}
        breakpoints={setBreakPoints}
        modules={[Pagination, Navigation]}
        loop={false}
        pagination={width > 992}
        onSwiper={setSwiper}
      >
        {renderSlides()}
      </Swiper>
      {elementNum > 4 && (
        <div className="slider-button-container">
          <span className="slider-prev">
            <button
              data-anchor-link
              className="prev-button"
              type="button"
              onClick={swiperPrev}
              data-testid="prev-button"
            >
              {<ArrowLeft />}
              <Accessibility location={previous} />
            </button>
          </span>
          <span className="slider-next">
            <button
              data-anchor-link
              className="next-button"
              type="button"
              onClick={swiperNext}
              data-testid="next-button"
            >
              {<ArrowRight />}
              <Accessibility location={next} />
            </button>
          </span>
        </div>
      )}
    </div>
  );
};
export default SliderBlockV4;
