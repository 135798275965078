import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { ImageInterface } from "../../types/SanityTypes";
import { graphql, useStaticQuery } from "gatsby";
import { Container } from "react-bootstrap";
import SliderBlockV4 from "../SliderBlockV4";
import "./styles.scss";
import { Link } from "../Link";
import { Translations } from "../../utils/algoliaProductsQuery";
import { event11 } from "../../analytics/event11";

interface ProductSliderV4Interface {
  heading?: string;
  carouselList: {
    filterOption: string;
    listType: string;
    content: ProductV4Interface[];
  }[];
  theme?: {
    name: string;
  };
  backgroundColor?: {
    value: string;
  };
  ctaLink?: {
    slug: {
      current: string;
    };
  };
  ctaLabel?: string;
}

interface ProductV4Interface {
  _id: string;
  _rawImages: ImageInterface[];
  title: string;
  url: string;
  smartProductId: string;
  paSmartProductId: string;
  paUPC: string;
  subBrand: {
    name: string;
  };
  name: string;
  slug: {
    current: string;
  };
  tags?: {
    paTagName: string;
    tagName: string;
  }[];
}

const ProductSliderV4: FunctionComponent<ProductSliderV4Interface> = ({
  heading,
  carouselList,
  theme,
  ctaLink,
  ctaLabel,
  backgroundColor
}) => {
  const { sanitySiteSettings, language, market } = useContext(LocalizedContext);
  const translations = Translations[language];
  const data: ProductV4Interface[] = useStaticQuery(graphql`
    query {
      allSanityProductV2(filter: { isHiddenOnWeb: { ne: true } }) {       
        nodes {
          market
          _id
          smartProductId
          paSmartProductId
          paUPC
          _rawImages(resolveReferences: { maxDepth: 10 })
          displayReviews
          subBrand {
            name
          }
          name
          slug {
            current
          }
          tags {
            paTagName
            tagName
          }
        }
      }
    }
  `).allSanityProductV2.nodes;

  const MAX_SUBBRAND_PRODUCTS = 4;

  const [activeList, setActiveList] = useState(carouselList?.[0]?.filterOption || "");

  const filtered = data.filter((node: any) => node.market === market);

  const filteredData: ProductV4Interface[] = filtered.filter(product =>
    product.tags?.find(
      tag =>
        (tag.tagName || "").toLowerCase() === (translations.spray || "").toLowerCase() ||
        (tag.paTagName || "").toLowerCase() === (translations.spray || "").toLowerCase()
    )
  );

  const listResolver = (): ProductV4Interface[] => {
    const currentList = carouselList.find(list => list.filterOption === activeList);
    switch (currentList?.listType) {
        case "custom":
            return currentList.content;
        case "best-seller":
            return getBestSellers(filteredData.filter(product =>
              product.tags?.find(
                tag =>
                  (tag.tagName || "").toLowerCase() === (translations.bestSeller || "").toLowerCase() ||
                  (tag.paTagName || "").toLowerCase() === (translations.bestSeller || "").toLowerCase()
              )
            ));
        default:
          return filteredData.filter(product =>
            product.tags?.find(
              tag =>
                (tag.tagName || "").toLowerCase() === (translations[currentList?.listType] || "").toLowerCase() ||
                (tag.paTagName || "").toLowerCase() === (translations[currentList?.listType] || "").toLowerCase()
            )
          );
    }
};

  const getBestSellers = (data: ProductV4Interface[]): ProductV4Interface[] => {
    const bestSellersBySubBrand: Record<string, ProductV4Interface[]> = {};
    data.forEach(product => {
      const subBrand = product.subBrand || "default";

      if (!bestSellersBySubBrand[subBrand.name]) {
          bestSellersBySubBrand[subBrand.name] = [];
        }
      if (bestSellersBySubBrand[subBrand.name].length < MAX_SUBBRAND_PRODUCTS) {
        bestSellersBySubBrand[subBrand.name].push(product);
      }
    });
    return Object.values(bestSellersBySubBrand).flat();
  };

  const [selectedProducts, setSelectedProducts] = useState<ProductV4Interface[]>(listResolver);

  const createThemeClassName = (className: string) => `theme--${className?.replace(" ", "").toLocaleLowerCase()}`;

  const filterTags = () =>
    carouselList.map(list => (
      <button
        key={list.filterOption}
        className={`btn-primary filter-btn ${activeList === list.filterOption ? "active" : ""}`}
        onClick={() => setActiveList(list.filterOption)}
      >
        {list.filterOption}
      </button>
    ));

  useEffect(() => {
    setSelectedProducts(listResolver());
    event11(selectedProducts, sanitySiteSettings?.brandName, "Product Slider");
  }, [activeList]);

  return (
    <section
      style={{ backgroundColor: backgroundColor?.value }}
      className={`product-slider product-slider-v4 ${theme && createThemeClassName(theme.name)}`}
    >
      <Container fluid>
        {heading && <h2 className="heading">{heading}</h2>}
        <nav className="nav-filters">{filterTags()}</nav>
        {selectedProducts && <SliderBlockV4 data={selectedProducts} type={"productV2"} />}
        {ctaLink && (
          <Link to={ctaLink.slug.current} className="cta-btn btn-primary">
            { ctaLabel ? ctaLabel : "See all products" }
          </Link>
        )}
      </Container>
    </section>
  );
};

export default ProductSliderV4;
