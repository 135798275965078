import React, { FunctionComponent, MouseEvent, useContext } from "react";
import { Link } from "../Link";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import { WhereToBuyButton } from "../WhereToBuy";
import { LocalizedContext } from "web-common/src/services/LocalizedContextService";

interface SlideInterface {
  id?: string;
  heading?: string;
  image?: ImageInterface | null;
  link?: string;
  upc?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  subBrand?: string;
  brandName?: string;
  category?: string;
  imageHeight?: number;
  imageWidth?: number;
}

const SlideV4: FunctionComponent<SlideInterface> = ({
  link,
  image = null,
  heading = "",
  id = "",
  upc = "",
  subBrand,
  category = "",
  brandName,
  imageHeight,
  imageWidth,
  onClick = () => {} // eslint-disable-line @typescript-eslint/no-empty-function
}) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const { sanityLabels } = useContext(LocalizedContext);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const defineImageWidth = (defaultValue: number) => {
    return imageWidth || defaultValue;
  };
  const defineImageHeight = (defaultValue: number) => {
    return imageHeight || defaultValue;
  };

  const itemImage = image && (
    <picture>
      <source
        media={"(min-width: 1200px)"}
        srcSet={
          urlBuilder
            .image(image)
            .auto("format")
            .quality(80)
            .width(defineImageWidth(386))
            .height(defineImageHeight(386))
            .format("webp")
            .url() as string
        }
      />
      <img
        src={
          urlBuilder
            .image(image)
            .auto("format")
            .quality(80)
            .width(defineImageWidth(365))
            .height(defineImageHeight(365))
            .format("webp")
            .url() as string
        }
        alt={image.alt}
      />
    </picture>
  );

  return (
    <div className="card-container-v4">
      <Link _id={id} to={link as string} onClick={onClick} className="heading-container-v4">
        <div className={"slide-image-v4"}>{itemImage}</div>
        {heading && (
          <div className="heading-content">
            {subBrand ? <div className="heading-title">{subBrand}</div> : null}
            <div className="heading-subtitle">{heading}</div>
          </div>
        )}
      </Link>
      <div className="cta-container">
        <WhereToBuyButton productCode={upc} productName={heading} productCategory={category} brand={brandName || ""} />
        <Link to={link as string} onClick={onClick} className="details btn-primary">
          {sanityLabels?.ctaLabels?.details}
        </Link>
      </div>
    </div>
  );
};
export default SlideV4;
